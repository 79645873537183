<template>
  <div class="saved-creators">
    <Loader v-if="isLoadingPayload" />

    <template v-else>
      <!-- Creator snippets section -->
      <div v-if="customerSavedCreatorsPayload?.manuallySaved?.length" class="creator-snippets-section saved-creators__section">
        <div class="creator-snippets-section__header">
          <h2 class="global-h2 creator-snippets-section__title">Saved Creators</h2>

          <LinkWithIcon
            class="creator-snippets-section__manage-link"
            color="blue"
            isDottedUnderline
            iconInset="auto -3px auto auto"
            @click="isSavedCreatorsManageModeOn = !isSavedCreatorsManageModeOn"
          >
            <template #icon>
              <IconEmbedded name="pencil_2" :size="16" />
            </template>
            <span v-if="isSavedCreatorsManageModeOn">Cancel managing</span>
            <span v-else>Manage</span>
          </LinkWithIcon>
        </div>

        <TransitionScale class="creator-snippets-section__snippets-list" group no-move :duration="170" easing="ease-in-out" tag="ul">
          <CreatorSnippet
            class="creator-snippets-section__snippet"
            v-for="creator in customerSavedCreatorsPayload?.manuallySaved.slice(0, howManyManuallySavedToShow)"
            :key="creator.shrpaCreatorId"
            :creator="creator"
            :isWithDeleteButton="isSavedCreatorsManageModeOn"
            @delete="
              async () => {
                customerSavedCreatorsPayload.manuallySaved = customerSavedCreatorsPayload?.manuallySaved.filter(c => c.shrpaCreatorId !== creator.shrpaCreatorId);
                const payload = await CustomerSavedCreatorsRepo.loadAllData(String(route.params.pageId));
                if (payload) customerSavedCreatorsPayload = payload;
              }
            "
            @clickOnCtaButton="() => domRefGetStartedModal?.open(creator)"
            :deleteCallback="creatorId => CustomerSavedCreatorsRepo.unSaveCreator(String(route.params.pageId), creatorId)"
          />
        </TransitionScale>

        <ShowMoreBtnSnippet
          v-if="howManyManuallySavedToShow < customerSavedCreatorsPayload?.manuallySaved.length"
          class="creator-snippets-section__show-more-button"
          @click="howManyManuallySavedToShow += cardsInOneRow"
        >
          <IconEmbedded name="caret-bottom_3" :size="18" color="rgba(120, 120, 120, 1)" style="position: relative; top: 4px; right: 4px" />Show more
        </ShowMoreBtnSnippet>
      </div>
      <!-- / Creator snippets section -->

      <!-- Creator snippets section -->
      <div v-if="customerSavedCreatorsPayload?.chosenForCollabs?.length" class="creator-snippets-section saved-creators__section">
        <div class="creator-snippets-section__header">
          <h2 class="global-h2 creator-snippets-section__title">Creators I've Worked With</h2>
        </div>

        <TransitionScale class="creator-snippets-section__snippets-list" group no-move :duration="170" easing="ease-in-out" tag="ul">
          <CreatorSnippet
            class="creator-snippets-section__snippet"
            v-for="creator in creatorsChosenForCollabsFiltered.slice(0, howManyChosenToShow)"
            :key="creator.shrpaCreatorId"
            :creator="creator"
            ctaButtonText="Save"
            @save="
              async () => {
                const payload = await CustomerSavedCreatorsRepo.loadAllData(String(route.params.pageId));
                if (payload) customerSavedCreatorsPayload = payload;
              }
            "
            :saveCallback="creatorId => CustomerSavedCreatorsRepo.saveCreator(String(route.params.pageId), creatorId)"
          />
        </TransitionScale>

        <ShowMoreBtnSnippet v-if="howManyChosenToShow < creatorsChosenForCollabsFiltered.length" class="creator-snippets-section__show-more-button" @click="howManyChosenToShow += cardsInOneRow">
          <IconEmbedded name="caret-bottom_3" :size="18" color="rgba(120, 120, 120, 1)" style="position: relative; top: 4px; right: 4px" />Show more
        </ShowMoreBtnSnippet>
      </div>
      <!-- / Creator snippets section -->

      <!-- Creator snippets section -->
      <div v-if="customerSavedCreatorsPayload?.appliedToCollabs?.length" class="creator-snippets-section saved-creators__section">
        <div class="creator-snippets-section__header">
          <h2 class="global-h2 creator-snippets-section__title">Previous Applicants</h2>
        </div>

        <TransitionScale class="creator-snippets-section__snippets-list" group no-move :duration="170" easing="ease-in-out" tag="ul">
          <CreatorSnippet
            class="creator-snippets-section__snippet"
            v-for="creator in creatorsAppliedToCollabsFiltered?.slice(0, howManyAppliedToShow)"
            :key="creator.shrpaCreatorId"
            :creator="creator"
            ctaButtonText="Save"
            @save="
              async () => {
                const payload = await CustomerSavedCreatorsRepo.loadAllData(String(route.params.pageId));
                if (payload) customerSavedCreatorsPayload = payload;
              }
            "
            :saveCallback="creatorId => CustomerSavedCreatorsRepo.saveCreator(String(route.params.pageId), creatorId)"
          />
        </TransitionScale>

        <ShowMoreBtnSnippet v-if="howManyAppliedToShow < creatorsAppliedToCollabsFiltered.length" class="creator-snippets-section__show-more-button" @click="howManyAppliedToShow += cardsInOneRow">
          <IconEmbedded name="caret-bottom_3-5" color="rgba(120, 120, 120, 1)" :size="16" style="position: relative; top: 3px; right: 5px" />Show more
        </ShowMoreBtnSnippet>
      </div>
      <!-- / Creator snippets section -->

      <!-- Creator snippets section -->
      <div v-if="customerSavedCreatorsPayload?.creatorsNear?.length" class="creator-snippets-section saved-creators__section">
        <div class="creator-snippets-section__header">
          <h2 class="global-h2 creator-snippets-section__title">{{ customerSavedCreatorsPayload?.appliedToCollabs.length > 1 ? "More Potential Collaborators" : "Potential Collaborators" }}</h2>
        </div>

        <TransitionScale class="creator-snippets-section__snippets-list" group no-move :duration="170" easing="ease-in-out" tag="ul">
          <CreatorSnippet
            class="creator-snippets-section__snippet"
            v-for="creator in creatorsNearFiltered.slice(0, howManyNearToShow)"
            :key="creator.shrpaCreatorId"
            :creator="creator"
            ctaButtonText="Save"
            @save="
              async () => {
                const payload = await CustomerSavedCreatorsRepo.loadAllData(String(route.params.pageId));
                if (payload) customerSavedCreatorsPayload = payload;
              }
            "
            :isCtaButtonDisabled="customerSavedCreatorsPayload?.manuallySaved.map(c => c.shrpaCreatorId).includes(creator.shrpaCreatorId)"
            :saveCallback="creatorId => CustomerSavedCreatorsRepo.saveCreator(String(route.params.pageId), creatorId)"
          />
        </TransitionScale>

        <ShowMoreBtnSnippet v-if="howManyNearToShow < creatorsNearFiltered.length" class="creator-snippets-section__show-more-button" @click="howManyNearToShow += cardsInOneRow">
          <IconEmbedded name="caret-bottom_3" :size="18" color="rgba(120, 120, 120, 1)" style="position: relative; top: 4px; right: 4px" />Show more
        </ShowMoreBtnSnippet>
      </div>
      <!-- / Creator snippets section -->

      <GetStartedModal ref="domRefGetStartedModal" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, Ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import CustomerSavedCreatorsRepo from "@repos/CustomerSavedCreatorsRepo";

// Components
import { TransitionScale } from "@morev/vue-transitions";
import CreatorSnippet from "./CreatorSnippet.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import ShowMoreBtnSnippet from "@views/Creator/CreatorDashboard/snippets/ShowMoreBtnSnippet.vue";
import GetStartedModal from "./GetStartedModal.vue";
import Loader from "@components/Loader/Loader.vue";

// Global variables & injections
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const screenSizeUpdateKey = inject("screenSizeUpdateKey") as Ref<symbol>;

// Types
import { ScreenSize } from "@contracts/screenSize";
import { CustomerSavedCreatorsPayload, CustomerSavedCreatorSummary } from "@contracts/customerSavedCreators";

// Router
const route = useRoute();

// DOM refs ===================================================================
const domRefGetStartedModal = ref<any>(null);

// Manage saved creators list =================================================
const isSavedCreatorsManageModeOn = ref<boolean>(false);

// Expand the lists ===========================================================
const howManyManuallySavedToShow = ref<number>(8);
const howManyAppliedToShow = ref<number>(4);
const howManyChosenToShow = ref<number>(4);
const howManyNearToShow = ref<number>(8);

const cardsInOneRow = computed<number>(() => {
  return {
    mobile: 2,
    tablet: 3,
    "tablet-large": 4,
    laptop: 4,
    desktop: 4,
    "desktop-wide": 4,
  }[screenSize.value];
});

watch(
  () => screenSizeUpdateKey.value,
  () => {
    howManyManuallySavedToShow.value = cardsInOneRow.value * 2;
    howManyAppliedToShow.value = cardsInOneRow.value;
    howManyChosenToShow.value = cardsInOneRow.value;
    howManyNearToShow.value = cardsInOneRow.value * 2;
  }
);

// Load the data ==============================================================
const customerSavedCreatorsPayload = ref<CustomerSavedCreatorsPayload | null>(null);
const isLoadingPayload = ref<boolean>(false);

onMounted(async () => {
  isLoadingPayload.value = true;
  customerSavedCreatorsPayload.value = await CustomerSavedCreatorsRepo.loadAllData(String(route.params.pageId));
  isLoadingPayload.value = false;
});

// Manually saved creator Id's ================================================
const savedCreatorIds = computed<Array<string>>(() => {
  return customerSavedCreatorsPayload.value.manuallySaved.map(c => c.shrpaCreatorId);
});

// chosenForCollabs with no manuallySaved creators ============================
const creatorsChosenForCollabsFiltered = computed<Array<CustomerSavedCreatorSummary>>(() => {
  return customerSavedCreatorsPayload.value.chosenForCollabs.filter(c => !savedCreatorIds.value.includes(c.shrpaCreatorId));
});

// appliedToCollabs with no manuallySaved creators ============================
const creatorsAppliedToCollabsFiltered = computed<Array<CustomerSavedCreatorSummary>>(() => {
  return customerSavedCreatorsPayload.value.appliedToCollabs.filter(c => !savedCreatorIds.value.includes(c.shrpaCreatorId));
});

// creatorsNear with no manuallySaved creators ============================
const creatorsNearFiltered = computed<Array<CustomerSavedCreatorSummary>>(() => {
  return customerSavedCreatorsPayload.value.creatorsNear.filter(c => !savedCreatorIds.value.includes(c.shrpaCreatorId));
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Creator snippets section ===================================================
.creator-snippets-section {
  &__header {
    padding-bottom: 16px;
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
  }

  &__manage-link {
  }

  &__snippets-list {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0 0 $desktop-wide-grid-gap-width;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__snippet {
    width: $desktop-wide-right-col-width-1-4th;
  }

  &__show-more-button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .creator-snippets-section {
    &__snippets-list {
      gap: $desktop-grid-gap-width;
      margin: 0 0 $desktop-grid-gap-width;
    }

    &__snippet {
      width: $desktop-right-col-width-1-4th;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-snippets-section {
    &__snippets-list {
      gap: $laptop-grid-gap-width;
      margin: 0 0 $laptop-grid-gap-width;
    }

    &__snippet {
      width: $laptop-right-col-width-1-4th;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-snippets-section {
    &__snippets-list {
      gap: $tablet-large-grid-gap-width;
      margin: 0 0 $tablet-large-grid-gap-width;
    }

    &__snippet {
      width: $tablet-large-content-width-1-4th;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-snippets-section {
    &__snippets-list {
      gap: $tablet-grid-gap-width;
      margin: 0 0 $tablet-grid-gap-width;
    }

    &__snippet {
      width: $tablet-content-width-1-3rd;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-snippets-section {
    &__snippets-list {
      gap: $mobile-grid-gap-width;
      margin: 0 0 $mobile-grid-gap-width;
    }

    &__snippet {
      width: $mobile-content-width-1-2nd;
    }
  }
}

// Saved creators =============================================================
.saved-creators {
  width: $desktop-wide-right-col-width;
  gap: 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .saved-creators {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .saved-creators {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .saved-creators {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .saved-creators {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .saved-creators {
    width: $mobile-content-width;
  }
}
</style>
