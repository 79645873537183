<template>
  <div class="content-wrapper" style="color: rgba(91, 91, 91, 1); font: 16px/20px sans-serif">
    <h1 class="global-h1" style="margin-bottom: 20px">Terms of Service</h1>

    <p class="global-p" style="margin-bottom: 30px">
      <span class="global-h5" style="margin-bottom: 10px; display: block">Last Modified: March 19 2024</span> These Terms of Service are entered into by and between you, an individual, organization or
      commercial entity (“<b>you</b>” or “<b>your</b>”) and Shrpa, LLC ("<b>Company</b>", "<b>we</b>" or "<b>us</b>"). The following Terms of Service, together with the Privacy Policy, and any other
      documents expressly incorporated by reference (collectively, these "<b>Terms</b>"), govern your access to and use of shrpa.com (referred to herein as the “<b>Platform</b>” or the “<b>Site</b>”),
      including any content, functionality, materials, and services offered on, through or in connection with or through the Platform, whether as a guest or a registered user.
    </p>

    <div class="text-block-with-line-dividers" style="margin-bottom: 30px">
      <p class="global-h4" style="margin-bottom: 23px; font-weight: 600">
        THESE TERMS GOVERN YOUR USE OF THIS SITE. BY ACCESSING AND USING THIS SITE, YOU AGREE TO COMPLY WITH THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS THIS SITE OR ANY OF THE
        INFORMATION OR CONTENT CONTAINED HEREIN. WE RESERVE THE RIGHT TO UPDATE THESE TERMS AT ANY TIME BY POSTING AN UPDATED COPY OF THESE TERMS TO THIS SITE AND YOU WAIVE THE RIGHT TO NOTICE OF ANY
        SPECIFIC CHANGES. BY CONTINUING TO ACCESS AND USE THE SITE AFTER THE POSTING OF UPDATED TERMS, YOU AGREE TO THE UPDATED TERMS.
      </p>
      <p class="global-h4" style="font-weight: 600">
        PLEASE REVIEW THE DISPUTE RESOLUTION PROVISION SET FORTH BELOW CAREFULLY. YOU ARE REQUIRED TO RESOLVE DISPUTES WITH THE COMPANY ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY
        ENTERING INTO THESE TERMS, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTAND THE TERMS OF THE AGREEMENT AND the CONSEQUENCES THEREOF.
      </p>
    </div>

    <p class="global-p" style="margin-bottom: 30px">
      This Site is offered only to users in the United States of America who are 18 years of age or older. You may not access or use this Site from outside the United States of America or in any
      manner that could subject the Company to international laws. By using this Site, you represent and warrant that you meet the eligibility requirements to use this Site and that you accept these
      Terms.
    </p>

    <h2 id="1ThePlatform" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#1ThePlatform"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">1</span> The Platform</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      The Platform connects destinations, destination marketing organizations, and other organizations (referred to herein, as “Destination(s)”) for the purpose of procuring user generated travel
      content (“Destination Content”) from content creators (“Creator(s)”) through paid collaborations (transaction collectively referred to as a “Paid Collaboration”). The Company reserves the right
      to withdraw or amend the Platform, and any service or material provided on the Platform, in its sole discretion without prior notice. We will not be liable if for any reason all or any part of
      the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or to the entire Platform, at our discretion.
    </p>

    <h2 id="2AccountCreationAndSecurity" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#2AccountCreationAndSecurity"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">2</span> Account Creation &amp; Security</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      To access the Platform, you may be required to create an account and provide certain registration details or other information. It is a condition of your use of the Platform that all the
      information you provide is correct, current, and complete. If you choose or are provided with, a username, password, or any other piece of information as part of our security procedures, you
      must treat such information as confidential, and you must not disclose it to any other person or entity. You acknowledge that your account is personal to you and agree not to provide any other
      person with access using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other
      breach of security. You agree that you are solely responsible (to us and to others) for the activity that occurs under your account. We have the right to disable any username, password, or other
      identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms.
    </p>

    <h2 id="3Creators" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#3Creators"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">3</span> Creators</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      By registering to as a Creator, Creator represents and warrants to Company that the information provided by Creator in the Platform is true and correct, including but not limited to any
      qualifications represented by Creator in Creator’s user profile. Creator warrants that Creator will perform all services to the best of Creator’s abilities and in accordance with all applicable
      laws, industry standards, and any agreed upon instructions from the Company or the applicable Destination. Creator agrees that in addition to all other rights and remedies available to Company
      at law or in equity, Company shall have the right to immediately remove Creator’s access to the Site without liability if Creator breaches the foregoing representations.
    </p>

    <p class="global-p" style="margin-bottom: 30px">
      By posting Destination Content to the Platform or otherwise providing Destination Content to the Company, Creator represents and warrants that Creator is the sole owner of, or that it has all
      necessary right, title, and licenses in the Destination Content, to post the Destination Content to the Platform and/or provide the Destination Content to the Company without infringing the
      rights of any third party. Creator hereby grants to Company a worldwide, irrevocable, perpetual, fully paid, transferable, sub-licensable, non-exclusive right and license to
      <b
        >fully exploit and distribute Destination Content, and to resell, reproduce, display, publicly perform, publicly display, digitally transmit sound and video recordings, and create derivative
        works of Destination Content.</b
      >
    </p>

    <p class="global-p" style="margin-bottom: 30px">
      Creator acknowledges that during any Paid Collaboration, Creator may engage in activities with inherent risk. Creators expressly represents that Creator understands the risks associated with the
      Paid Collaboration, which may include, physical or psychological injury, pain, illness, suffering, disfigurement, temporary or permanent disability, economic or emotional loss or death. In the
      event that Creator should require medical care or treatment as a result of Creator’s participation in the Paid Collaboration, Creator understands and agrees that Creator is solely responsible
      for any costs incurred as a result of such treatment and that neither the Company, Company Representatives, nor the applicable Destination are liable for medical or other expenses. To the extent
      not prohibited by law, Creator expressly agrees to indemnify and hold harmless the Company, the Company Representatives, and the applicable Destination (each of which are intended third-party
      beneficiaries of this provision) from and against any and all liabilities, expenses, damages and costs, including, but not limited to, reasonable attorneys’ fees and costs, related to Creator’s
      participation in the Paid Collaboration.
    </p>

    <div class="text-block-with-line-dividers" style="margin-bottom: 30px">
      <p class="global-h4" style="font-weight: 600">
        BY ACCEPTING A PAID COLLABORATION, CREATOR EXPRESSLY ACKNOWLEDGES AND AGREES THAT CREATOR’S PARTICIPATION IN A PAID COLLABORATION INVOLVES INHERENT RISK AND DANGERS. CREATOR AGREES TO BE
        RESPONSIBLE FOR CREATOR’S OWN WELFARE AND ACCEPTS ANY AND ALL RISKS OF UNANTICIPATED EVENTS, ILLNESS, INJURY, EMOTIONAL TRAUMA, OR DEATH. CREATOR ACKNOWLEDGES AND AGREES THAT THE COMPANY IS
        NOT RESPONSIBLE FOR THE NEGLIGENT OR WILLFUL ACTS OR FAILURES OF ANY THIRD PARTY IN CONNECTION WITH A PAID COLLABORATION.
      </p>
    </div>

    <h2 id="5FeesPayableToCompany" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#5FeesPayableToCompany"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">5</span> Fees Payable to Company</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      By accepting these Terms, the Destination agrees to pay to Company the applicable fees for its use of the Platform as set forth in an order form. Subscription fees are non-refundable. Your
      subscription will automatically renew according to your selected subscription plan unless changed or terminated by you prior to the renewal date within your account settings. Shrpa reserves the
      right to change subscription fees at any time upon thirty (30) days’ notice, provided that the price change will not become effective until renewal of your subscription. If you disagree with the
      change in price, then you may terminate your subscription before renewal within your account settings at any time prior to the change in price becoming effective. Your continued use of the
      Platform after the price change becomes effective constitutes agreement to pay the changed amount for the duration of your subscription term.
    </p>

    <p class="global-p" style="margin-bottom: 20px">
      Shrpa uses third party payment processors to process your payments. Your payment information is subject to the privacy policy and terms of service of the applicable payment processor. You
      represent and warrant that
    </p>

    <ul class="global-ul global-ul--with-no-style" style="margin-bottom: 30px">
      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">I</span>the payment information
          you supply to us or our service providers is true, correct and complete,
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">II</span>you are duly authorized
          to use such payment method for the purchase,
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">III</span>charges incurred by you
          will be honored by your payment method, and
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">IV</span>you will pay charges
          incurred by you at the posted prices, and all applicable taxes, if any.
        </p>
      </li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">
      <b
        >By placing an order, you authorize Shrpa to bill your payment instrument in accordance with the terms of the applicable subscription payment plan, as applicable (as well as any applicable
        taxes), and you further agree to pay any charges so incurred without offset or deduction.</b
      >
    </p>

    <p class="global-p" style="margin-bottom: 30px">
      <b>
        Should automatic billing fail for any reason, Shrpa will notify you via your account or your email address on file. If you dispute any charges, you must provide written notice to Shrpa within
        fourteen (14) days after Shrpa charges your payment instrument. In the event that Shrpa is unable to charge your payment instrument as authorized when you subscribed to the Platform, Shrpa
        may, in its sole discretion, without limiting Shrpa’s other rights and remedies:
      </b>
    </p>

    <ul class="global-ul global-ul--with-no-style" style="margin-bottom: 30px">
      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">I</span>
          <b>seek to update account information through third party sources (i.e., your bank or payment processor) to continue charging your payment instrument as authorized by you;</b>
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">II</span>
          <b>charge interest on the past due amount at the rate of 3.0% per month calculated daily and compounded monthly or, if lower, the highest rate permitted under applicable law;</b>
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">III</span
          ><b>charge any and all costs incurred by Shrpa in collecting any late payments or interest, including attorneys’ fees, court costs, and collection agency fees; and/or</b>
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">IV</span>
          <b>suspend your and your authorized users’ access to any portion or all of the Platform until such amounts are paid in full.</b>
        </p>
      </li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">
      We may periodically offer promotions that may affect pricing and that are governed by terms and conditions separate from these Terms. If there is a conflict between the terms for a promotion and
      these Terms, the promotion terms will govern.
    </p>

    <p class="global-p" style="margin-bottom: 30px">There is no charge to Creators for use of the Platform. Creator is responsible for all expenses during the Paid Collaboration.</p>

    <h2 id="6IntellectualPropertyRightsTrademarks" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#6IntellectualPropertyRightsTrademarks"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">6</span> Intellectual Property Rights; Trademarks.</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      This Site, its content (including and without limitation all text, graphics, logos, button icons, images, photographs, metadata, video clips, and audio clips and the compilation of content
      (“Content”)), and all software used to operate the Platform is the exclusive intellectual property of the Company or its licensors and is protected by copyright, trademark, and other laws of the
      United States and other countries. Nothing in these Terms shall be construed as transferring any ownership rights or granting, by implication, inference, estoppel, or otherwise, any license or
      other right to use the Site or any of its content, software, or other intellectual property.
    </p>

    <p class="global-p" style="margin-bottom: 30px">
      This Site features logos and other trademarks and service marks that are the property of or are licensed to Company. The Site may also include trademarks or service marks of third parties. All
      these trademarks are the property of their respective owners, and you agree not to use or display them in any manner without the prior written permission of the applicable trademark owner.
    </p>

    <h2 id="7UserRestrictions" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#7UserRestrictions"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">7</span> User Restrictions</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      You are authorized to use the Site for your personal use only so long as you do not remove any copyright or other notices that appear on any material available on the Site (collectively, “Site
      Content”). You agree that you will not otherwise copy, modify, display, sell, broadcast, transmit, or distribute any Site Content in any manner or medium, including by uploading the material or
      otherwise making the material available online. Your right to use the Site is subject to your strict compliance with these Terms and your right to use the Site shall automatically terminate upon
      any violations. These rights are non-exclusive, limited, and revocable by us at any time in our sole discretion without advance notice or liability. As your right to access and use the Site is
      personal to you, you may not assign nor transfer your right and any attempt to do so is void.<b />
    </p>

    <p class="global-p" style="margin-bottom: 30px">
      You agree not to use the Site in any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any antitrust or competition laws,
      or laws regarding the export of data or software to and from the US or other countries). Additionally, you agree not to: use the Site in any manner that could disable, overburden, damage, or
      impair the Site or interfere with any other party's use of the Site, including their ability to engage in real time activities through the Site; use any robot, spider, or other automatic device,
      process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site; use any manual process to monitor or copy any of the material on the Site,
      or for any other purpose not expressly authorized herein, without our prior written consent; use any device, software, or routine that interferes with the proper working of the Site; introduce
      any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; attempt to gain unauthorized access to, interfere with, damage, or disrupt any
      parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site; attack the Site via a denial-of-service attack or a distributed
      denial-of-service attack; impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail
      addresses or user names associated with any of the foregoing); engage in any other conduct that restricts or inhibits anyone's use of the Platform, or which, as determined by us in our sole
      discretion, may harm the Company or users of the Platform or expose them to potential liability; or otherwise attempt to interfere with the proper working of the Site.
    </p>

    <h2 id="8MonitoringAndEnforcementTermination" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#8MonitoringAndEnforcementTermination">
        <span class="number-circle" style="position: absolute; inset: -4px auto auto 0">8</span> Monitoring and Enforcement; Termination.
        <span style="font-weight: 500">We have the right to:</span>
      </a>
    </h2>
    <ul class="global-ul global-ul--lower-li-mark" style="margin-bottom: 30px">
      <li class="terms-of-use-font" style="padding-left: 20px">
        Take any action that we deem necessary or appropriate in our sole discretion, if we believe that any content posted by a user to the Platform violates the Terms, infringes any intellectual
        property right or other right of any person or entity, threatens the personal safety of users of the Platform or the public or could create potential liability for Company.
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        Disclose any identifying information we have about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights, their
        right to privacy, or other right.
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform.
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">Terminate or suspend your access to all or part of the Platform in our sole discretion.</li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">
      Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information
      of anyone posting any materials on or through the Platform.
      <b
        >YOU WAIVE AND HOLD HARMLESS COMPANY AND COMPANY REPRESENTATIVES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN DURING OR AS A RESULT OF ITS INVESTIGATIONS
        AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</b
      >
      You may refer any inaccuracies or concerns to Company by email, at <CopyTextNew>hello@shrpa.com</CopyTextNew>. However, we cannot and do not undertake to review material posted by other users
      before they are posted on the Platform and cannot ensure prompt removal of objectionable listings after they have been posted. Accordingly, we assume no liability for any action or inaction
      regarding transmissions, communications or content provided by any user or by any third party.
    </p>

    <h2 id="9UserContributionStandards" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#9UserContributionStandards"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">9</span> User Contribution Standards</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      The Platform may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, social media links, and other interactive features (collectively, “Interactive
      Services”) that allow you to post, submit, publish, display, or transmit to other users or other persons (hereinafter, “post”) content or materials, whether provided to us directly by you or
      that you authorized us to access from third parties such as FaceBook, Google, or Apple (collectively, “User Contributions”) on or through the Platform. User Contributions must comply with the
      Community Rules set out in these Terms. Any User Contribution you post will be considered non-confidential and non-proprietary. By posting any User Contribution on the Site, you grant us and our
      affiliates and service providers, and each of their and our respective licensees, successors, and assigns, the right to use, reproduce, reformat, incorporate into other works, advertise, modify,
      perform, display, distribute, and otherwise disclose to third parties any such material for any purpose whatsoever. You represent and warrant that you own or control all rights in and to the
      User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns,
      and all of your User Contributions do and will comply with these Terms. You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not
      the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content or
      accuracy of any User Contributions posted by you or any other user of the Platform. You acknowledge and agree that any User Contributions you place or that you authorize us to place on the
      Platform may be viewed by other users and may be viewed by any person visiting or participating in the Platform.
    </p>

    <h2 id="10CommunityRules" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#10CommunityRules"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">10</span> Community Rules</a>
    </h2>
    <p class="global-p" style="margin-bottom: 20px">
      You agree to treat other users in a courteous and respectful manner, both on and off our Platform. By using the Platform, you agree that you will not:
    </p>

    <ul class="global-ul global-ul--lower-li-mark" style="margin-bottom: 40px">
      <li class="terms-of-use-font" style="padding-left: 20px">
        post any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable;
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">use the Platform for any purpose that is illegal or prohibited by these Terms;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or
        regulations or that otherwise may be in conflict with these Terms and our Privacy Policy;
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">be likely to deceive any person;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">promote any illegal activity, or advocate, promote, or assist any unlawful act;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">impersonate any person, or misrepresent your identity or affiliation with any person or organization;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        spam or solicit money or other items of value from other users, or involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising; or
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">misrepresent your identity, age, qualifications, or affiliations;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">use another user’s account, or share an account with another person;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">create another account if we have previously terminated your account, unless you have received permission in writing from us.</li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">
      You acknowledge and agree that the Company may investigate and/or terminate your account if you have violated these Terms, misused the Platform, or behaved in a way that the Company regards as
      inappropriate or unlawful, including actions or communications that occur on or off the Platform.
    </p>

    <h2 id="11SafetyYourInteractionsWithOtherUsers" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#11SafetyYourInteractionsWithOtherUsers"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">11</span> Safety; Your Interactions with Other Users</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      While the Company strives to create a positive user experience for Destinations and Creators, the Company is not responsible for the conduct of any users on or off the Platform. You agree to use
      caution in all interactions with other users.
    </p>

    <div class="text-block-with-line-dividers" style="margin-bottom: 30px">
      <p class="global-h4" style="font-weight: 600">
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT SHRPA DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND
        OF ITS USERS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS.
      </p>
    </div>

    <h2 id="12CopyrightInfringement" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#12CopyrightInfringement"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">12</span> Copyright Infringement</a>
    </h2>
    <p class="global-p" style="margin-bottom: 20px">
      We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Platform infringes on the copyright or other intellectual property
      rights ("Infringement") of any person or entity. If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes
      copyright infringement, please submit your claim via email to <CopyTextNew>DMCA@shrpa.com</CopyTextNew>, with the subject line: "Copyright Infringement" and include in your claim a detailed
      description of the alleged Infringement as detailed below, under "DMCA Notice and Procedure for Copyright Infringement Claims". You may be held accountable for damages (including costs and
      attorneys' fees) for misrepresentation or bad faith claims on the infringement of any content found on and/or through the Platform on your copyright.
      <br />
      <br />

      <span class="global-h4" style="display: inline">DMCA Notice and Procedure for Copyright Infringement Claims</span>
      <br />
      <br />

      You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for
      further detail):
    </p>

    <ul class="global-ul global-ul--lower-li-mark" style="margin-bottom: 40px">
      <li class="terms-of-use-font" style="padding-left: 20px">an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the
        copyrighted work;
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">identification of the URL or other specific location on the Platform where the material that you claim is infringing is located;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">your address, telephone number, and email address;</li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
      </li>
      <li class="terms-of-use-font" style="padding-left: 20px">
        <span>
          a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's
          behalf.
        </span>
      </li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">You may contact our Copyright Agent at <CopyTextNew>DMCA@shrpa.com</CopyTextNew>.</p>

    <h2 id="13UserFeedback" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#13UserFeedback"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">13</span> User Feedback</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      You acknowledge and agree that users may publish and request Company to publish on their behalf information on the Platform about the user, such as feedback, composite feedback, or verification
      of identity or credentials. However, such information is based solely on unverified data that users voluntarily submit to Company and does not constitute and will not be construed as an
      introduction, endorsement, or recommendation by Company. Company provides such information solely for the convenience of its users. You acknowledge and agree that user feedback benefits the
      marketplace, all users, and the efficiency of the Platform and you specifically request that Company post composite or compiled feedback about users, including yourself, on the Platform. You
      acknowledge and agree that feedback results for you, including your user rating, wherever referenced, and other user contributions highlighted by Company on the Site or otherwise (“Composite
      Information”), if any, will include user comments, user ratings, indicators of user satisfaction, and other feedback provided exclusively by other users. You further acknowledge and agree that
      Company will make Composite Information available to other users, including composite or compiled feedback. You agree not to use the Composite Information to make any credit, credit valuation,
      underwriting, or other similar decision about any other user.
    </p>

    <h2 id="14PrivacyPolicy" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#14PrivacyPolicy"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">14</span> Privacy Policy</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      All personal information we collect on the Platform is subject to our Privacy Policy. By using the Platform, you consent to all actions taken by us with respect to your personal information in
      compliance with the Privacy Policy.
    </p>

    <h2 id="15LinkingToTheSiteAndSocialMediaFeatures" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#15LinkingToTheSiteAndSocialMediaFeatures"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">15</span> Linking to the Site and Social Media Features</a>
    </h2>
    <p class="global-p" style="margin-bottom: 20px">
      You may link to our Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to
      suggest any form of association, approval, or endorsement on our part without our express written consent. The Site may provide certain social media features that enable you to:
    </p>

    <ul class="global-ul global-ul--with-no-style" style="margin-bottom: 30px">
      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">I</span>link from your own or
          certain third-party websites to certain content on this Site,
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">II</span>send emails or other
          communications with certain content, or links to certain content, on this Site, and
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">III</span>cause limited portions
          of content on this Site to be displayed or appear to be displayed on your own or certain third-party websites.
        </p>
      </li>
    </ul>

    <p class="global-p" style="margin-bottom: 30px">
      You may use these features solely as they are provided by us and solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions
      we provide with respect to such features. Subject to the foregoing, you must not do any of the following: establish a link from any website that is not owned by you to the Site; cause the Site
      or portions of it to be displayed on, or appear to be displayed by, any other website; link to any part of the Site other than the homepage; or otherwise take any action with respect to the
      materials on this Site that is inconsistent with any other provision of these Terms. The website from which you are linking, or on which you make certain content accessible, must comply in all
      respects with the Community Rules set out in these Terms. You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw
      linking permission without notice. We may disable all or any social media features and any links at any time without notice in our discretion.
    </p>

    <h2 id="16ThirdPartyContent" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#16ThirdPartyContent"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">16</span> Third Party Content</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      The Platform may include content provided by third parties, including materials provided by other buyers, sellers, users, bloggers and third-party licensors, syndicators, aggregators and
      reporting services. All statements and opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Company, are
      solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Company. We are not responsible, or liable
      to you or any third party, for the content or accuracy of any materials provided by any third parties.
    </p>
    <p class="global-p" style="margin-bottom: 30px">
      If the Platform contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements,
      including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may
      arise from your use of them. If you decide to access any of the third-party websites linked to this Company Platform, you do so entirely at your own risk and subject to the terms and conditions
      of use for those websites.
    </p>

    <h2 id="17GeographicRestrictions" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#17GeographicRestrictions"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">17</span> Geographic Restrictions</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      We make no claims that the Company Platform or any of its Content or materials are accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain
      persons or in certain countries. If you access the Platform from outside the United States, you do so on your own initiative and are responsible for compliance with all local laws.
    </p>

    <h2 id="18DisclaimerOfWarranties" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#18DisclaimerOfWarranties"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">18</span> Disclaimer of Warranties</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      YOUR USE OF THE PLATFORM, THE CONTENT AVAILABLE ON THE SITE, AND ANY SERVICES OR MATERIAL OBTAINED THROUGH THE SITE IS SOLELY AT YOUR OWN RISK. THE PLATFORM, THE CONTENT AND ANY SERVICES OR
      MATERIAL OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER COMPANY, NOR ITS EMPLOYEES, AGENTS,
      CONTRACTORS, OFFICERS, DIRECTORS, AFFILIATES, OR THEIR PREDECESSORS IN INTEREST, SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE “COMPANY REPRESENTATIVES”), MAKES ANY WARRANTY OR REPRESENTATION WITH
      RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY, NOR THE COMPANY REPRESENTATIVES, REPRESENT OR
      WARRANT THAT THE SITE, ITS CONTENT OR ANY SERVICES OR MATERIAL OBTAINED THROUGH THE SITE, INCLUDING BUT NOT LIMITED TO DESTINATION CONTENT, WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES, CONTENT, OR
      MATERIAL OBTAINED THROUGH THE SITE, INCLUDING BUT NOT LIMITED TO DESTINATION CONTENT, WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
      EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY
      WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>

    <div class="text-block-with-line-dividers" style="margin-bottom: 30px">
      <p class="global-h4" style="font-weight: 600">
        NEITHER THE COMPANY OR ANY COMPANY REPRESENTATIVES MAKE CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS ANY DESTINATIONS OR ADVERTISERS LISTED ON THE SERVICE OR THIRD PARTIES THAT
        OFFER GOODS OR SERVICES THROUGH THE SERVICE, OR THE PLATFORM’S OTHER USERS. ACCORDINGLY, NEITHER THE COMPANY OR ANY COMPANY REPRESENTATIVES ARE LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR
        DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER, CREATOR, OR DESTINATION MISUSES YOUR CONTENT, IDENTITY OR PERSONAL
        INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE CREATORS, DESTINATIONS OR ADVERTISERS LISTED OR FEATURED ON THE PLATFORM. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES
        OFFERED BY THIRD PARTIES THROUGH THE PLATFORM IS AT YOUR OWN DISCRETION AND RISK.
      </p>
    </div>

    <p class="global-p" style="margin-bottom: 30px">
      WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA
      OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR MATERIAL OBTAINED THROUGH THE PLATFORM OR TO YOUR ACCESS OR DOWNLOADING OF ANY CONTENT POSTED ON IT, OR ON ANY SITE
      LINKED TO IT. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE SUFFERED BY ANY USER OF THE SITE AS A RESULT OF THE POSTING OF ANY CONTENT TO THE SITE OR RELIANCE ON ANY CONTENT AVAILABLE ON THE
      SITE.
    </p>

    <h2 id="14LimitationOnLiability" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#14LimitationOnLiability"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">14</span> Limitation on Liability</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      IN NO EVENT WILL COMPANY OR ANY COMPANY REPRESENTATIVES BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
      PLATFORM, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR DESTINATION CONTENT OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
      INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
      BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF CONTENT OR DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>

    <p class="global-p" style="margin-bottom: 15px">
      TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF THE AMOUNT
      OF PLATFORM FEES PAID BY YOU TO USE THE PLATFORM IN THE THREE MONTHS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM AND $100. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID BY YOU TO USE THE PLATFORM,
      YOU SHALL BE LIMITED TO, AT MOST, INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PROHIBITED BY APPLICABLE LAW, AND SHALL NOT BE ENTITLED TO ANY OTHER DAMAGES, REGARDLESS OF THE CAUSE OF ACTION.
      NOTHING IN THESE TERMS SHALL LIMIT OR EXCLUDE OUR LIABILITY FOR:
    </p>

    <ul class="global-ul global-ul--with-no-style" style="margin-bottom: 30px">
      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">I</span>DEATH OR PERSONAL INJURY
          RESULTING FROM THE COMPANY’S WILLFUL MISCONDUCT;
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">II</span>FRAUD OR FRAUDULENT
          MISREPRESENTATIONS; OR
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 0">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">III</span>ANY OTHER LIABILITY THAT
          CANNOT BE EXCLUDED BY APPLICABLE LAW.
        </p>
      </li>
    </ul>

    <h2 id="15Indemnification" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#15Indemnification"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">15</span> Indemnification</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      You agree to defend, indemnify and hold harmless Company and the Company Representatives from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
      (including attorneys' fees) arising out of or relating to your violation of these Terms, your use of the Platform, including, but not limited to, your User Contributions and/or Destination
      Content, any use of the Platform's Content, services and products other than as expressly authorized in these Terms, and/or your use of any information obtained from the Platform.
    </p>

    <h2 id="16DisputeResolution" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#16DisputeResolution"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">16</span> Dispute Resolution</a>
    </h2>
    <div class="text-block-with-line-dividers" style="margin-bottom: 30px">
      <p class="global-h4" style="font-weight: 600">
        Please read this Arbitration Agreement carefully. It is part of your contract with the Company and affects your rights. It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
        ACTION WAIVER.
      </p>
    </div>

    <ul class="global-ul global-ul--with-no-style" style="margin-bottom: 30px">
      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">a</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Applicability of Arbitration Agreement.</i></b> &nbsp;&nbsp; All claims and disputes (excluding claims for injunctive or other equitable relief as set
          forth below) in connection with the Agreement, the Platform, or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be
          resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English. This
          Arbitration Agreement applies to you and the Company, and to any Company Representatives, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under
          the Agreement. The Parties agree that Destinations are an intended express third-party beneficiary of this arbitration agreement.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">b</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Notice Requirement and Informal Dispute Resolution.</i></b> &nbsp;&nbsp; Before either party may seek arbitration, the party must first send to the
          other party a written Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief. A Notice to the Company should be sent to
          <CopyTextNew>hello@shrpa.com</CopyTextNew>. We will send Notice to you using the email address that we have for you in our records. After the Notice is received, you and the Company may
          attempt to resolve the claim or dispute informally. If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an
          arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any,
          to which either party is entitled.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">c</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Arbitration Rules.</i></b> &nbsp;&nbsp; Arbitration shall be initiated through the American Arbitration Association (“<b>AAA</b>”), an established
          alternative dispute resolution provider (“<b>ADR Provider</b>”) that offers arbitration as set forth in this section. If AAA is not available to arbitrate, the parties shall agree to select
          an alternative ADR Provider. The rules of the ADR Provider shall govern all aspects of the arbitration, including but not limited to the method of initiating and/or demanding arbitration,
          except to the extent such rules are in conflict with the Agreement. The AAA Consumer Arbitration Rules (“<b>Arbitration Rules</b>”) governing the arbitration are available online at
          www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration shall be conducted by a single, neutral arbitrator. Any claims or disputes where the total amount of the award sought is
          less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief. For claims or disputes where
          the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules. Any hearing will be held in a
          location within 100 miles of your residence, unless you reside outside of the United States, and unless the parties agree otherwise. If you reside outside of the U.S., the arbitrator shall
          give the parties reasonable notice of the date, time and place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent
          jurisdiction. Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR
          Provider.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">d</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Additional Rules for Non-Appearance Based Arbitration.</i></b> &nbsp;&nbsp; If non-appearance based arbitration is elected, the arbitration shall be
          conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration. The arbitration shall not involve any
          personal appearance by the parties or witnesses unless otherwise agreed by the parties.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">e</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Time Limits.</i></b> &nbsp;&nbsp; If you or the Company pursue arbitration, the arbitration action must be initiated and/or demanded within the statute
          of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.
          <b
            >NOT WITHSTANDING THE FOREGOING, ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THE AGREEMENT OR THE PLATFORM MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE
            OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</b
          >
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">f</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Authority of Arbitrator.</i></b> &nbsp;&nbsp; If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and the
          Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of
          all or part of any claim. The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law,
          the AAA Rules, and the Agreement. The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based,
          including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the
          arbitrator is final and binding upon you and the Company.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">g</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Waiver of Jury Trial.</i></b> &nbsp;&nbsp;
          <b>THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY</b>, instead electing that all claims and disputes shall
          be resolved by arbitration under this Arbitration Agreement. Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are
          subject to very limited review by a court. In the event any litigation should arise between you and the Company in any state or federal court in a suit to vacate or enforce an arbitration
          award or otherwise, <b>YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL</b>, instead electing that the dispute be resolved by a judge.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">h</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Waiver of Class or Consolidated Actions.</i></b> &nbsp;&nbsp;
          <b
            >ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY USER.</b
          >
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">i</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Confidentiality.</i></b> &nbsp;&nbsp; All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance
          therewith, shall be strictly confidential. The parties agree to maintain confidentiality unless otherwise required by law. This paragraph shall not prevent a party from submitting to a court
          of law any information necessary to enforce these Terms, to enforce an arbitration award, or to seek injunctive or equitable relief.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">j</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Severability.</i></b> &nbsp;&nbsp; If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court
          of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">k</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Right to Waive.</i></b> &nbsp;&nbsp; Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against
          whom the claim is asserted. Such waiver shall not waive or affect any other portion of this Arbitration Agreement.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">l</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Survival of Agreement.</i></b> &nbsp;&nbsp; This Arbitration Agreement will survive the termination of your relationship with the Company.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">m</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Small Claims Court.</i></b> &nbsp;&nbsp; Notwithstanding the foregoing, either you or the Company may bring an individual action in small claims court.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">n</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Emergency Equitable Relief.</i></b> &nbsp;&nbsp; Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or
          federal court in order to maintain the status quo pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration
          Agreement.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">o</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Claims Not Subject to Arbitration.</i></b> &nbsp;&nbsp; Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse
          Act, and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.
        </p>
      </li>

      <li style="padding-left: 60px">
        <p class="global-p" style="padding: 0; margin-bottom: 10px">
          <span class="number-circle number-circle--small" style="padding-bottom: 2px; margin: 0 5px 0 -30px; position: relative; top: -1px; color: rgba(0, 0, 0, 1)">p</span>
          <b style="color: rgba(0, 0, 0, 1)"><i>Courts.</i></b> &nbsp;&nbsp; In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby
          agree to submit to the personal jurisdiction of the courts located within the State of Minnesota, for such purpose.
        </p>
      </li>
    </ul>

    <h2 id="18WaiverAndSeverability" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#18WaiverAndSeverability"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">18</span> Waiver and Severability</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      No waiver of by Company of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and
      any failure of Company to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is held to be invalid, illegal
      or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
    </p>

    <h2 id="19EntireAgreement" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#19EntireAgreement"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">19</span> Entire Agreement</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      The Terms and our Privacy Policy, and referenced policies or documents, constitute the sole and entire agreement between you and Company with respect to the Platform and supersede all prior and
      contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the Platform.
    </p>

    <h2 id="20ChangesToTheTerms" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#20ChangesToTheTerms"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">20</span> Changes to the Terms</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Company Platform
      thereafter. Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page from time to time so you
      are aware of any changes, as they are binding on you.
    </p>

    <h2 id="21ContactUs" class="global-h2" style="padding-left: 43px; margin: 50px auto 10px; position: relative">
      <a href="#21ContactUs"><span class="number-circle" style="position: absolute; inset: -4px auto auto 0">21</span> Contact Us</a>
    </h2>
    <p class="global-p" style="margin-bottom: 30px">
      All feedback, comments, requests for technical support and other communications relating to the Company or the Platform should be directed to: <CopyTextNew>hello@shrpa.com</CopyTextNew>.
    </p>
  </div>
</template>

<script setup lang="ts">
import { onMounted, inject, Ref } from "vue";
import { useHead } from "@unhead/vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import CopyTextNew from "@components/CopyTextNew.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

onMounted(() => {
  useHead({ title: "Terms of Service for Shrpa" });
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.text-block-with-line-dividers {
  padding: 25px 0;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    border: 1px rgba(0, 0, 0, 0.15) solid;
    border-width: 1px 0 1px;
    display: block;
    position: absolute;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.number-circle {
  width: 33px;
  height: 33px;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font:
    700 20px/20px "Quicksand",
    sans-serif;
  background: rgba(0, 0, 0, 0.1);
}

.number-circle--small {
  width: 25px;
  height: 25px;
  font:
    700 15px/15px "Quicksand",
    sans-serif;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .number-circle {
  }
}

.global-p {
  padding-left: 43px;
  color: rgba(91, 91, 91, 1);
  font: 16px/23px sans-serif;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .global-p {
    padding-left: 0px;
  }
}

.global-ul {
  padding-left: 43px;
  color: rgba(91, 91, 91, 1);
  font: 16px/23px sans-serif;
}

.global-ul--lower-li-mark li::before {
  top: 11px;
}

.global-ul--with-no-style li::before {
  display: none;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .global-ul {
    padding-left: 0;
  }
}

.divider {
  width: 70%;
  border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
}

.terms-of-use-font {
  color: rgba(91, 91, 91, 1);
  font: 16px/23px sans-serif;
}

:global(html) {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}
</style>
