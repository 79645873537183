<template>
  <SrpModal v-model:isVisible="_isVisible" size="medium">
    <template #content>
      <!-- "Get started" form -->
      <div class="get-started-form" style="margin-bottom: 20px">
        <div class="get-started-form__header">
          <h1 class="global-h1 get-started-form__title">Get Started</h1>
        </div>

        <ul class="get-started-form__option-snippets-list">
          <!-- Option snippet -->
          <li
            :class="{
              'option-snippet': true,
              'option-snippet--disabled': snippet.isBeta,
              'get-started-form__option-snippet': true,
            }"
            v-for="(snippet, index) in interactionWithCreatorOptions"
            :key="index"
          >
            <img
              :class="{
                'option-snippet__icon': true,
                'option-snippet__icon--disabled': snippet.isBeta,
              }"
              :src="`/images/${snippet.icon}Icon.svg`"
              :alt="snippet.icon"
            />

            <div class="option-snippet__title-and-button">
              <h2
                :class="{
                  'global-h2': true,
                  'option-snippet__title': true,
                  'option-snippet__title--disabled': snippet.isBeta,
                }"
              >
                {{ snippet.title }}
              </h2>

              <div class="option-snippet__cta-button-wrap">
                <div v-if="snippet.isBeta" class="option-snippet__beta-badge">beta</div>

                <SrpButton
                  :class="{
                    'option-snippet__cta-button': true,
                    'option-snippet__cta-button--disabled': snippet.isBeta,
                  }"
                  :tag="snippet.ctaTo ? 'router-link' : 'div'"
                  :to="snippet.ctaTo"
                  fill="outlined"
                  :size="screenSize === 'mobile' ? 'small' : 'normal'"
                  @click="() => snippet.ctaCallback?.()"
                >
                  {{ snippet.ctaButtonText }}
                  <template v-if="!snippet.isBeta" #iconRight>
                    <IconEmbedded name="arrow-right_3" :size="20" />
                  </template>
                </SrpButton>
              </div>
            </div>
          </li>
          <!-- / Option snippet -->
        </ul>
      </div>
      <!-- / "Get started" form -->
    </template>

    <template #footer>
      <SrpButton color="gray" fill="outlined" @click="_isVisible = false">Cancel</SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, inject, Ref, computed } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { CustomerSavedCreatorsPayload, CustomerSavedCreatorSummary } from "@contracts/customerSavedCreators";

// Global variables & injections
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const globalRoot = inject("globalRoot") as any;

// Visibility =================================================================
const _isVisible = ref<boolean>(false);

// "Interaction with creator" options =========================================
const interactionWithCreatorOptions = computed<
  Array<{
    title: string;
    icon: string;
    isBeta: boolean;
    ctaButtonText: string;
    isCtaButtonWithIcon: boolean;
    ctaTo?: object;
    ctaCallback?: Function;
  }>
>(() => [
  {
    title: "Set up a Shrpa Creator Visit",
    icon: "rocket",
    isBeta: false,
    ctaButtonText: "Set up",
    isCtaButtonWithIcon: true,
    ctaTo: { name: "PaidCollabCommunityAdmin", params: { communityId: globalRoot.orgInContext?.customerId }, hash: `#createdForCreatorId=${_creatorSummary.value.shrpaCreatorId}` },
    ctaCallback: () => {},
  },
  // {
  //   title: `Message ${_creatorSummary.value.name}`,
  //   icon: "newMessage",
  //   isBeta: false,
  //   ctaButtonText: "Open Messages",
  //   isCtaButtonWithIcon: true,
  // },
  {
    title: "Initiate a Custom Collab",
    icon: "monitorBrush",
    isBeta: true,
    ctaButtonText: "Coming soon",
    isCtaButtonWithIcon: false,
    ctaCallback: () => console.log("CLICK: Initiate a Custom Collab"),
  },
]);

// Creator ====================================================================
const _creatorSummary = ref<CustomerSavedCreatorSummary | null>(null);

// Define expose ==============================================================
defineExpose({
  open: (creatorSummary: CustomerSavedCreatorSummary) => {
    _creatorSummary.value = creatorSummary;
    _isVisible.value = true;
  },
  close: () => (_isVisible.value = false),
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Option snippet =============================================================
.option-snippet {
  padding: 40px 20px 50px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(231, 236, 240, 1);

  &--disabled {
    background: rgba(231, 236, 240, 0.5);
  }

  &__icon {
    width: 90px;
    margin-bottom: 8px;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__title-and-button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 25px;
    text-align: center;
    font-weight: 600;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__cta-button-wrap {
    position: relative;
    z-index: 0;
  }

  &__beta-badge {
    height: 20px;
    padding: 0 7px;
    border-radius: 100px;
    position: absolute;
    inset: -10px -8px auto auto;
    z-index: 1;
    color: rgba(0, 0, 0, 1);
    background: rgba(241, 186, 97, 1);
    pointer-events: none;
  }

  &__cta-button {
    &::before {
      background: rgba(255, 255, 255, 1) !important;
    }

    &--disabled {
      color: rgba(0, 0, 0, 1);
      pointer-events: none;

      &::before {
        border-color: rgba(0, 0, 0, 0.5);
        border-style: dashed;
        background: transparent !important;
      }
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .option-snippet {
    padding: 21px 25px 25px;
    flex-direction: row;

    &__icon {
      width: 70px;
      margin: 0 14px 0 0;
    }

    &__title-and-button {
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 12px;
      text-align: left;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .option-snippet {
    padding: 21px 25px 25px;
    flex-direction: row;

    &__icon {
      width: 60px;
      margin: 0 14px 0 0;
    }

    &__title-and-button {
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 12px;
      text-align: left;
    }
  }
}

// "Get started" form =========================================================
.get-started-form {
  &__header {
    padding-bottom: 22px;
    margin-bottom: 32px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__title {
  }

  &__option-snippets-list {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }

  &__option-snippet {
    width: calc(100% / 2 - ($desktop-wide-grid-gap-width * 1 / 2));
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .get-started-form {
    &__option-snippets-list {
      gap: $desktop-grid-gap-width;
    }

    &__option-snippet {
      width: calc(100% / 2.5 - ($desktop-grid-gap-width * 1 / 2));
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .get-started-form {
    &__option-snippets-list {
      gap: $laptop-grid-gap-width;
    }

    &__option-snippet {
      width: calc(100% / 2.5 - ($laptop-grid-gap-width * 1 / 2));
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .get-started-form {
    &__option-snippets-list {
      gap: $tablet-large-grid-gap-width;
    }

    &__option-snippet {
      width: calc(100% / 2.5 - ($tablet-large-grid-gap-width * 1 / 2));
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .get-started-form {
    &__option-snippets-list {
      gap: $tablet-grid-gap-width;
      flex-direction: column;
    }

    &__option-snippet {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .get-started-form {
    &__option-snippets-list {
      gap: $tablet-grid-gap-width;
      flex-direction: column;
    }

    &__option-snippet {
      width: 100%;
    }
  }
}
</style>
